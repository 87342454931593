/******************************************
 *  Author : Suraj Sharma
 *  Created On : Tue Dec 22 2020
 *  File : ApiServiceConstants.js
 *******************************************/

/**
 * Standard api response status which will be used across the application depending upon the
 * response of api service call (via axios).
 */
export const apiResponseStatuses = {
    // initial
    IDLE: 'IDLE',

    // when succeeding one request.
    SUCCESS: 'SUCCESS',

    // when an error occur.
    ERROR: 'ERROR',

    SENT_VERIFICATION_CODE: 'SENT_CODE',
    CODE_MISMATCH: 'CODE_MISMATCH',
}

/**
 * All the http status codes returned by the api server need to be defined here.
 * This is used to determine the type of API response after API call.
 * Refer: https://www.restapitutorial.com/httpstatuscodes.html
 */
export const httpStatusCodes = {
    // 1xx INFORMATIONAL
    INFORMATIONAL_CONTINUE: 100,

    // 2xx SUCCESS
    SUCCESS_OK: 200,
    SUCCESS_CREATED: 201,
    SUCCESS_NO_CONTENT: 204,

    // 3xx REDIRECTION
    REDIRECTION_MULTIPLE_CHOICES: 300,

    // 4xx CLIENT_ERROR
    CLIENT_ERROR_BAD_REQUEST: 400,
    CLIENT_ERROR_UNAUTHORIZED: 401,

    // 5xx SERVER_ERROR
    SERVER_ERROR_INTERNAL_SERVER_ERROR: 500,
}

// dev api server base address
export const API_SERVER_BASE =
'https://dev-greenstand.sustainably.run';


export const API_MASTER_PORTAL_AUTH_SERVER_BASE =
'https://dev-sr-auth.sustainably.run';

// // restaurant name search api base address
// export const RESTAURANT_SEARCH_BASE = 
// `https://stag-greenearthappeal.emvigotech.co.uk/panacea/index.php/user/get_restaurants_for_greenstand`;

// this api will list complete list of available restaurants
export const RESTAURANT_SEARCH_BASE = 
'https://stag-greenearthappeal.emvigotech.co.uk/panacea/index.php/user/get_complete_restaurants_for_greenstand_from_redis';

export const PANACEA_BASE = "https://stag-greenearthappeal.emvigotech.co.uk/panacea/index.php"
/**
 * All the axios request configuration that needs to be applied while initializing axios service
 * has to be added here.
 */

export const apiServerConfig = {
    baseURL: API_SERVER_BASE,
    timeout: 60000,
};

export const apiEndPoints = Object.freeze({
    authentication: {
        loginByEmailAndPassword: '',
        signUp: '',
        resetPassword: ''
    },

    customerInfo: {
        verifyPanaceaUser: '/panacea-api/verify_panacea_user',
        addSocialShareInfo: '/auth-api/share_profile'
    },

    trees: {
        fetchTrees: '/es-api/fecth_elastic_data',
        fetchDynamicTrees: '/es-api/fetch_dynamic_data',
        fetchTreeDetail: '/es-api/fetch_trees',
        fetchPlantedTreeCount: '/api/get_wallet',
        allowedTreeCount: '/api/get_allowed_tree',
        claimTreeRequest: '/api/create_claim_request',
        verifyTransaction: '/srm-api/verify_claim',
        fetchCompanyTrees: '/es-api/fetch_company_elastic_data',
        getCompanyForest: '/srm-api/company_forest',
        updateForestInfo : '/v1/companyForestDetails',
        fetchAllTrees: '/es-api/fecth_elastic_data_all_trees',
    },
    impossibleVideo: {
        getVideoInfo: '/video-api/impossible-video-info'
    },

    signUp: 'auth-api/create_user',
    userInfo: 'auth-api/manage_user_public',
    verifyAccount: 'auth-api/verify_user',
    uploadImage: 'img-upload',
    userInfoUsingUserId: 'auth-api/manage_user',
});